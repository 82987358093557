

// ---
// Event triggered after a comment has been sent to backend
// --- 

var on_communication_update_required_callbacks = [];

export function registerOnCommunicationUpdateRequired(func) {
  on_communication_update_required_callbacks.push(func);
}

export function onCommunicationUpdateRequired() {
  on_communication_update_required_callbacks.forEach((f) => f());
}


// ---
// Event triggered after selected item has been changed
// --- 

var on_selected_feature_changed_callbacks = [];

export function registerOnSelectedFeatureChanged(func) {
  on_selected_feature_changed_callbacks.push(func);
}

export function onSelectedFeatureChanged(feature_id) {  
  on_selected_feature_changed_callbacks.forEach((f) => f(feature_id));
}

// ---
// Event triggered after comments have been loaded
// --- 

var on_comments_loaded_callbacks = [];

export function registerOnCommentsLoaded(func) {
  on_comments_loaded_callbacks.push(func);
}

export function onCommentsLoaded(feature_id) {  
  on_comments_loaded_callbacks.forEach((f) => f(feature_id));
}


// ---
// Event in when session expired
// --- 

var on_session_expired_callbacks = [];

export function registerOnSessionExpired(func) {
  on_session_expired_callbacks.push(func);
}

export function onSessionExpired() {  
  on_session_expired_callbacks.forEach((f) => f());
}
