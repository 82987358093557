import "./statistics_app.scss";

import React, { useState } from 'react'
import { StatisticsArea } from "../components/statistics_area";
import { get_project_name } from "../../js/url";
import { useIsAuthenticated } from "react-auth-kit";

export function StatisticsApp() {
    const isAuthenticated = useIsAuthenticated();
    const [project, setProject] = useState(get_project_name());

    const hideStatisticsApp = (evt) => {
        var statistics_area = document.getElementById("div_statistics_area");
        var statistics_form = document.getElementById("div_statistics_form");

        if (!statistics_form.contains(evt.target)) {
            statistics_area.style.display = "none";
            statistics_area.style.pointerEvents = "none";
        }
    }

    return (
        <div id="div_statistics_app" className='div_statistics_app'>
            <div id="div_statistics_area" onClick={hideStatisticsApp} className="div_statistics_area">
                <StatisticsArea isAuthenticated={isAuthenticated()} project={project}/>
            </div>
        </div>
    )
}
