import "./comment_app.scss";

import React from 'react'
import { CommentForm } from "../components/comment_form";

export function CommentApp() {

    const hideCommentApp = (evt) => {
        var comment_area = document.getElementById("div_comment_area");
        var comment_form = document.getElementById("div_comment_form");

        if (!comment_form.contains(evt.target)) {
            comment_area.style.display = "none";
            comment_area.style.pointerEvents = "none";
        }
    }

    return (
        <div id="div_comment_app" className='div_comment_app'>
            <div id="div_comment_area" onClick={hideCommentApp} className="div_comment_area">
                <CommentForm/>
            </div>
        </div>
    )
}
