export const CONFIG_PROJECT = "Ngaoundere2";
export const CONFIG_MAP_PROJECTION = "EPSG:3857";

export const CONFIG_API_URL = "https://www.n-vision.ai/api/v1";
export const CONFIG_API_URL_DEV = "http://localhost:15000/api/v1";

export const CONFIG_TILE_SERVER_URL = "https://www.n-vision.ai/tiles/v1";
export const CONFIG_TILE_SERVER_URL_DEV = "http://localhost:15100/tiles/v1";

export const CONFIG_INFER_SERVER_URL = "https://www.n-vision.ai/infer/v1";
export const CONFIG_INFER_SERVER_URL_DEV = "http://localhost:15200/infer/v1";

export const CONFIG_GEOMETRY_SERVER_URL = "https://www.n-vision.ai/geometry/v1";
export const CONFIG_GEOMETRY_SERVER_URL_DEV = "http://localhost:15300/geometry/v1";

export const CONFIG_WFS_SERVER_URL = "https://www.n-vision.ai/srv";
export const CONFIG_WFS_SERVER_URL_DEV = "http://localhost:5400/srv";

export const CONFIG_DEV_MODE = false;

