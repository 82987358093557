import Draw from "ol/interaction/Draw.js";
import MapBrowserEventType from 'ol/MapBrowserEventType.js';


class DrawCustom extends Draw {

  constructor(options) {
    super(options);
    this.init_button = -1;
  }

  handleEvent(event) {
        
    if (this.sketchFeature_ === null) {
      if (event.originalEvent.type === MapBrowserEventType.POINTERUP) {
        this.init_button = event.originalEvent.button;
      } 
    }

    return super.handleEvent(event);
  }
}

export default DrawCustom;