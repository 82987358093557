
import VectorSource from 'ol/source/Vector.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import {all, bbox} from 'ol/loadingstrategy.js';
import {transform} from 'ol/proj.js';
import {transformExtent} from 'ol/proj';


import { CONFIG_MAP_PROJECTION } from './config.js';
import { get_project_name, get_api_url, get_task_id } from './url.js';

function _load_data(
    project_name, 
    task_id,
    data_type, 
    source, 
    extent, 
    resolution, 
    projection, 
    success, 
    failure
) {
    const t_start = performance.now();

    const extent_in_wgs84 = transformExtent(extent, "EPSG:3857", "EPSG:4326");

    const api_url = get_api_url();
    const api_endpoint = `${api_url}/query`;
    
    var url = `${api_endpoint}?` +
        `project=${project_name}&` +
        `type=${data_type}&` +
        `extent=${extent_in_wgs84.toString()}`;

    if (task_id != null) {
        url += `&task=${task_id}`;
    }

    console.log(url);

    const onError = function() {
        source.removeLoadedExtent(extent);
        failure();
    }

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onerror = onError;
    xhr.onload = function() {
        if (xhr.status == 200) {
            if (xhr.responseText === '{}') {
                console.log("empty");
            }        
            else {
                const data = JSON.parse(xhr.responseText);
                console.log(data['api_stats']);
                const features = source.getFormat().readFeatures(
                    data["data"], 
                {
                    dataProjection: 'EPSG:4326',
                    featureProjection: CONFIG_MAP_PROJECTION,
                }
                );
                source.addFeatures(features);
                success(features);
            }
        } else {
            onError();
        }

        const t_end = performance.now()

        console.log(`Loading took ${t_end - t_start} ms for ${url}`);
    }
    xhr.send();
}

export function setupInteractiveSource() {
        
    var source = new VectorSource({
        wrapX: false,
        format: new GeoJSON(),
        strategy: bbox, //all,
        loader: function(extent, resolution, projection, success, failure) {
    
            _load_data(
                get_project_name(),
                get_task_id(),
                "verified", 
                source, 
                extent, 
                resolution, 
                projection, 
                success, 
                failure
            );
        },
    });

    return source
}


export function setupAISource() {
    var source = new VectorSource({
        wrapX: false,
        format: new GeoJSON(),
        strategy: bbox, 
        loader: function(extent, resolution, projection, success, failure) {

            _load_data(
                get_project_name(),
                get_task_id(),
                "ai", 
                source, 
                extent, 
                resolution, 
                projection, 
                success, 
                failure
            );
        },
    });

    return source;
}


export function setupAOIsSource() {
    var source = new VectorSource({
        wrapX: false,
        format: new GeoJSON(),
        strategy: bbox, 
        loader: function(extent, resolution, projection, success, failure) {

            _load_data(
                get_project_name(),
                get_task_id(),
                "aois", 
                source, 
                extent, 
                resolution, 
                projection, 
                success, 
                failure
            );
        },
    });

    return source;
}
