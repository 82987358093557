import { getUserRoles } from "../src/apps/user_app";
import { get_project_name } from "./url";


function getProjectRoles() {    
    const project_name = get_project_name();
    const user_roles = getUserRoles();  
    
    const roles = user_roles[project_name] ?? {};
    
    return roles;
}


export function isExportAllowed() {
    const roles = getProjectRoles();
    console.log(roles)
    return roles.export !== undefined && roles.export;
}


export function isAoisAllowed() {
    const roles = getProjectRoles();
    return roles.aois !== undefined && roles.aois;
}
