import "./user_app.scss";

import React, { useEffect } from 'react'
import { useAuthHeader, useIsAuthenticated } from 'react-auth-kit'
import { useAuthUser, useSignOut } from 'react-auth-kit'

import { UserArea } from "../components/user_area";
import { LoginForm } from "../components/login_form";
import { RegisterForm } from "../components/register_form";

import { get_api_url } from "../../js/url";
import { showInfoText } from "../../js/info";
import { onSessionExpired, registerOnSessionExpired } from "../../js/callbacks";


var user_data_ = null;
var auth_data_ = null;
var user_roles_ = null;

var login_callbacks = [];
var logout_callbacks = [];


export function registerOnLoginEvent(func) {
  login_callbacks.push(func);
};

export function registerOnLogoutEvent(func) {
  logout_callbacks.push(func);
};

export function onLoginEvent(user_data, auth_data, user_roles=null) {
  setUserData(user_data);
  setAuthData(auth_data);

  if (user_roles) {
    setUserRoles(user_roles);
  }

  console.log("login");
  console.log(user_data)
  console.log(user_roles)

  login_callbacks.forEach(function (func) {
    func();
  })
}

export function onLogoutEvent() {
  setUserData(null);
  setAuthData(null);
  setUserRoles(null);

  console.log("logout");

  logout_callbacks.forEach(function (func) {
    func();
  })
}

export function isUserLoggedIn() {
  return user_data_ != null;
}

export function setUserData(user_data) {
    user_data_ = user_data;
}

export function getUserData() {
    return user_data_;
}

export function setAuthData(auth_data) {
    auth_data_ = auth_data;
}

export function getAuthData() {
    return auth_data_;
}

export function setUserRoles(user_roles) {
  user_roles_ = user_roles;
}

export function getUserRoles() {
  return user_roles_;
}

export function UserApp() {

    const isAuthenticated = useIsAuthenticated();
    const readAuthUser = useAuthUser();
    const readAuthHeader = useAuthHeader()
    const signOut = useSignOut();

    useEffect(() => {
      registerOnSessionExpired(function() {
        console.log("session expired => logout")
        signOut();
        onLogoutEvent();
      });
    }, []);

    if (isAuthenticated()) {
        console.log("user authenticated");        
        loadUserData(readAuthUser, readAuthHeader);
    } else {
        console.log("user not authenticated");
        onLogoutEvent();
    }

    return (
        <div id="div_user_app" className='div_user_app'>
            <div className="div_user_area">
                <UserArea/>
            </div>
            <LoginForm/>
            <RegisterForm/>
        </div>
    )
}

function loadUserData(readAuthUser, readAuthData) {
  const auth_user = readAuthUser();
  const auth_data = readAuthData();
    
  let email = auth_user.user.email;

  const api_url = get_api_url();
  const api_endpoint = `${api_url}/user`;
  const url = `${api_endpoint}?email=${email}`;

  fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': auth_data,
    },
  })
  .then((response) => {
    
    if (response.status === 200) {
      console.log("user data received");
      response.json()
        .then((data) => {
          console.log(data);
          onLoginEvent(data.user, auth_data, auth_user.roles);
        })
        .catch((e) => {
          console.log(e)
          showInfoText("Connection failure, please retry ...");
        });
    } else if (response.status === 401 || response.status === 422) {
      console.log("error while loading user data")
      onSessionExpired();
    } else {
      console.log("error while loading user data")
      showInfoText("Connection failure, please retry ...");
    }
  })
  .catch((e) => {
    console.log("cannot load user data");
    console.log(e);
    showInfoText("Connection failure, please retry ...");
  });
}
