
import {Fill, Stroke, Style} from 'ol/style.js';
import CircleStyle from 'ol/style/Circle';
import { extend } from 'ol/array.js';
import { MultiPoint } from 'ol/geom';


export function createEditingStyle(draw_vertices) {
  
  const styles = {};

  const white = [255, 255, 255, 1];
  const blue = [0, 153, 255, 1];
  const width = 1.5;

  styles['Polygon'] = [
    new Style({
      fill: new Fill({
        color: [255, 255, 255, 0.15],
      }),
    }),
  ];

  // TODO: show vertices only while editing a selected polygon (not while using SAM)
  styles['LineString'] = [
    new Style({
      stroke: new Stroke({
        color: white,
        width: width + 2,
      }),
    }),
    new Style({
      stroke: new Stroke({
        color: blue,
        width: width,
      }),
    }),    
  ];

  if (draw_vertices == true) {
    styles['LineString'].push(
      new Style({
        image: new CircleStyle({
          radius: 8,
          fill: new Fill({
            color: [255, 255, 255, 0.25],
          }),
        }),
        geometry: function (feature) {
          // return the coordinates of the line rings

          var coordinates = [];
          for (var ring of feature.getGeometry().getCoordinates()) {
            coordinates.push(...ring)
          }

          return new MultiPoint(coordinates);
        },
      }),
    )
  }

  styles['Point'] = [
    new Style({
      image: new CircleStyle({
        radius: width * 0,
        fill: new Fill({
          color: blue,
        }),
        stroke: new Stroke({
          color: white,
          width: width,
        }),
      }),
      zIndex: Infinity,
    }),
  ];

  styles['MultiPolygon'] = styles['Polygon'];
  styles['MultiLineString'] = styles['LineString'];
  styles['Circle'] = styles['Polygon'].concat(styles['LineString']);
  styles['MultiPoint'] = styles['Point'];
  styles['GeometryCollection'] = styles['Polygon'].concat(
    styles['LineString'],
    styles['Point']
  );

  return styles;
}


function createSelectStyle(draw_vertices) {
  const styles = createEditingStyle(draw_vertices);

  extend(styles['Polygon'], styles['LineString']);
  extend(styles['GeometryCollection'], styles['LineString']);

  return styles;
}

// ---
// Styles 
// ---


const BASE_OPACITY = 0.9299;
const STROKE_OPACITY = 0.65 * BASE_OPACITY;
const FILL_OPACITY_APPROVED = 0.25 * BASE_OPACITY;
const FILL_OPACITY_AI = 0.25 * BASE_OPACITY;
const FILL_OPACITY_AOIS = 0.0;


export const STYLE_BUILDINGS_APPROVED = new Style({  
    fill: new Fill({
      color: `rgba(0, 255, 0, ${FILL_OPACITY_APPROVED})`,
      opacity: 0.5,
    }),
    stroke: new Stroke({
      color: `rgba(255, 255, 255, ${STROKE_OPACITY})`,
      width: 2,
    }),
    opacity: 0.5,
  });
  

export const STYLE_BUILDINGS_COMMENTED = new Style({  
    fill: new Fill({
      color: `rgba(0, 0, 255, ${FILL_OPACITY_APPROVED})`,
      opacity: 0.5,
    }),
    stroke: new Stroke({
      color: `rgba(255, 255, 255, ${STROKE_OPACITY})`,
      width: 2,
      lineDash: [16, 8]
    }),
    opacity: 0.5,
  });
  
    
  
export const STYLE_BUILDINGS_AI = new Style({
    fill: new Fill({
      color: `rgba(145, 0, 0, ${FILL_OPACITY_AI})`,
      opacity: 0.5,
    }),
    stroke: new Stroke({
      color: `rgba(0, 0, 255, ${STROKE_OPACITY})`,
      width: 2,
    }),
    opacity: 0.5,
  });


export const STYLE_AOIS = [
  new Style({
    stroke: new Stroke({
      color: `rgba(255, 255, 0, ${STROKE_OPACITY})`,
      width: 8,
    }),
    opacity: 0.5,
  }),
  new Style({
    fill: new Fill({
      color: `rgba(0, 0, 0, ${FILL_OPACITY_AOIS})`,
      opacity: 0.5,
    }),
    stroke: new Stroke({
      color: `rgba(255, 0, 0, ${STROKE_OPACITY})`,
      width: 2,
      lineDash: [10],
      lineDashOffset: 10,
    }),
    opacity: 0.5,
  })
];


var editing_styles = createEditingStyle(true)
export const STYLE_POLYGON_TOOL = 
  function (feature, resolution) {
    return editing_styles[feature.getGeometry().getType()];
  };


var select_styles = createSelectStyle(true)
export const STYLE_SELECT_TOOL = 
  function (feature, resolution) {
    if (!feature.getGeometry()) {
      return null;
    }
    return select_styles[feature.getGeometry().getType()];
  };


var magic_styles = createSelectStyle(false)
export const STYLE_MAGIC_TOOL = 
  function (feature, resolution) {
    if (!feature.getGeometry()) {
      return null;
    }
    return magic_styles[feature.getGeometry().getType()];
  };


export const STYLE_TASK_CLIP_AREA = new Style({
    fill: new Fill({
      color: 'rgba(0, 0, 0, 1.0)',
    }),
});

  
export const STYLE_TASK_BORDER_AREA = new Style({
  fill: new Fill({
    color: 'rgba(70, 240, 240, 0.25)',
  }),
});


export const STYLE_HOVER_FEATURE = [
  new Style({
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0)',
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 0.6)',
      width: 2,
      lineDash: [8, 16],
    }),
  }),
  new Style({
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0)',
    }),
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.6)',
      width: 2,
      lineDash: [8, 16],
      lineDashOffset: 12
    }),
  }),
];
