import Translate from "ol/interaction/Translate.js";

class TranslateCustom extends Translate {
    
  constructor(options) {
    super(options);
  }
      
  handleMoveEvent(event) {   
    // overridden move event to show default mouse cursor
  }
}

export default TranslateCustom;
