
import {Map, View} from 'ol';
import {getUid} from 'ol';
import {Group as LayerGroup} from 'ol/layer.js';
import LayerSwitcher from 'ol-layerswitcher';

import {defaults as defaultInteractions} from 'ol/interaction.js';
import {ScaleLine} from 'ol/control.js';
import {KeyboardPanExtended} from './keyboard.js'

import {fromLonLat, toLonLat, transformExtent} from 'ol/proj';

import { CONFIG_MAP_PROJECTION } from './config';
import { load_project_info } from './project.js'
import { get_project_name, get_task_id, get_view_from_url, set_view_to_url } from './url';
import { get_api_url } from './url';
import { cmp_float } from './math.js';

export function setupMap(base_map_layers, overlay_layers) {

    var map_controls = [
        new ScaleLine({
            // bar: true,
            // text: true,
            // minWidth: 128,
            // steps: 2,
        }),
    ]

    var map_interactions = defaultInteractions({
        doubleClickZoom: false,
        altShiftDragRotate: false,
        shiftDragZoom: false,
        pinchRotate: false,
        keyboard: false,
        attribution: false,
    })

    const overlays = new LayerGroup({
        title: 'Overlays',
        layers: overlay_layers,
    })
    
    const base_maps = new LayerGroup({
        title: 'Base maps',
        layers: base_map_layers
    });

    const map = new Map({
        controls: map_controls,
        interactions: map_interactions,
        target: 'map',
        keyboardEventTarget: document,
        layers: [
            base_maps,
            overlays,
        ],
        view: new View({
            center: fromLonLat([0, 0]),
            zoom: 21,
            projection: CONFIG_MAP_PROJECTION,
        })
    });

    map.addInteraction(new KeyboardPanExtended());

    map.on('moveend', function(evt) {
        const center = map.getView().getCenter();

        if (cmp_float(center[0], 0.0) && cmp_float(center[1], 0.0)) {
            console.log("ignore move end");
            return
        }
        
        set_view_to_url(
            map.getView().getCenter(), 
            map.getView().getZoom()
        );
    })

    query_view_info(map);
      
    // ---
    // Setup layer switcher
    // ---

    var layer_div = document.getElementById("div_layers");

    LayerSwitcher.renderPanel(
        map,
        layer_div,
        {
            reverse: true,
            groupSelectStyle: "none"
        }
    );

    return map;
}


export function isFeatureAtPixel(map, feature, pixel) 
{
  let is_feature_at_pixel = false;

  if (map.hasFeatureAtPixel(pixel) === true) 
  {
    map.getFeaturesAtPixel(pixel).forEach(
      function(curr_feature) 
      {
        if (getUid(feature) == getUid(curr_feature)) 
        {
          is_feature_at_pixel = true;
        }
      }
    );
  }
  return is_feature_at_pixel;
}


async function query_view_info(map) {

    // First, try to get view from URL

    var view = get_view_from_url();

    if (view != null) 
    {
        map.getView().setCenter(view["center"]);
        map.getView().setZoom(view["zoom"]);
    } 
    else if (get_task_id() == null) 
    {     
        // If view is not set in URL and no task is specified => load it from the database

        load_project_info(function(data) {
            map.getView().fit(
                transformExtent(
                    data['extent'], 
                    'EPSG:4326', 
                    'EPSG:3857'
                )
            );
        })

        console.log("center changed");
    }
}
