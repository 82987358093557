
export enum StateMode {
    STATE_MODE_UNKNOWN = -1,
    STATE_MODE_INIT = 1,
    STATE_MODE_ORTHO = 2,
    STATE_MODE_VERIFICATION = 3,
};

export enum StateVerification {
    STATE_VERIFICATION_UNKNOWN = -1,
    STATE_VERIFICATION_STARTING = 0,
    STATE_VERIFICATION_DISABLED = 1,
    STATE_VERIFICATION_WAIT_FOR_SIGNIN = 2,
    STATE_VERIFICATION_ENABLED = 3,   
    STATE_VERIFICATION_READY = 4,    
    STATE_VERIFICATION_BUSY = 5,
};

export class AppData {

    state_mode_ : StateMode = -1;
    state_verification_ : StateVerification = -1;
    
    AppData() {
        this.reset();
    }

    reset() {
        this.state_mode_ = StateMode.STATE_MODE_UNKNOWN;
        this.state_verification_ = StateVerification.STATE_VERIFICATION_UNKNOWN;
    }

    getStateMode() : StateMode {
        return this.state_mode_;
    }

    getStateVerification() : StateVerification {
        return this.state_verification_;
    }

    setStateMode(state_mode: StateMode) {
        this.state_mode_ = state_mode;
    }

    setStateVerification(state_verification: StateVerification) {
        this.state_verification_ = state_verification;
    }
};
