import "./user_area.scss";

import React from 'react'

import { useIsAuthenticated } from 'react-auth-kit'
import { useAuthUser } from 'react-auth-kit'

import { LoginButton } from './login_button'
import { LogoutButton } from "./login_button";
import { is_viewer } from "../../js/url";


export function UserArea() {

    const isAuthenticated = useIsAuthenticated();
    const authUser = useAuthUser();

    if (is_viewer() == false) {
        if (isAuthenticated()) {
            let auth_data = authUser();
            return ( 
                <div id="div_user_logged_in" className="div_user_area_centered">
                    <div className="div_user_area_background">
                        <span className='span_user_name'>{`${auth_data.user.first_name}`}</span>
                        <LogoutButton/>
                    </div>
                </div>
            )
        } else {
            return (
                <div id="div_user_logged_out" className="div_user_area_centered">
                    <div className="div_user_area_background">
                        <LoginButton/>
                    </div>
                </div>
            )
        }
    }
}