// ---
// Info/error messages
// --- 

// Show/hide info text

export function showInfoText(message) {
    let div_info = document.getElementById("div_info");
    div_info.style.display = "flex";
    div_info.textContent = message;
  }
  
export function hideInfoText() {
    div_info.style.display = "none";
  }
  