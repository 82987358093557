import { KeyboardPan } from 'ol/interaction.js';

import EventType from 'ol/events/EventType.js';
import { pan } from 'ol/interaction/Interaction';
import KeyCode from 'ol/events/KeyCode.js';
import {rotate as rotateCoordinate} from 'ol/coordinate.js';


function noModifierKeysEvt(evt) {
   return !evt.altKey && !(evt.metaKey || evt.ctrlKey) && !evt.shiftKey
}

class KeyboardPanExtended extends KeyboardPan {
  
  constructor(options) {
    super(options);
  }

  handleEvent(mapBrowserEvent) {
    let stopEvent = false;
    if (mapBrowserEvent.type == EventType.KEYDOWN) {
      const keyEvent = (mapBrowserEvent.originalEvent);
      const keyCode = keyEvent.keyCode;
      const code = keyEvent.code;      
      if (
        this.condition_(mapBrowserEvent) &&
        (keyCode == KeyCode.DOWN ||
          keyCode == KeyCode.LEFT ||
          keyCode == KeyCode.RIGHT ||
          keyCode == KeyCode.UP ||
          code == 'KeyS' ||
          code == 'KeyA' ||
          code == 'KeyD' ||
          code == 'KeyW')
      ) {
        const map = mapBrowserEvent.map;
        const view = map.getView();
        const mapUnitsDelta = view.getResolution() * this.pixelDelta_;
        let deltaX = 0,
          deltaY = 0;
        if (keyCode == KeyCode.DOWN || code == 'KeyS') {
          deltaY = -mapUnitsDelta;
        } else if (keyCode == KeyCode.LEFT || code == 'KeyA') {
          deltaX = -mapUnitsDelta;
        } else if (keyCode == KeyCode.RIGHT || code == 'KeyD') {
          deltaX = mapUnitsDelta;
        } else if (keyCode == KeyCode.UP || code == 'KeyW') {
          deltaY = mapUnitsDelta;
        }
        const delta = [deltaX, deltaY];
        rotateCoordinate(delta, view.getRotation());
        pan(view, delta, this.duration_);
        keyEvent.preventDefault();
        stopEvent = true;
      }
    }
    return !stopEvent;
  }
}

export { KeyboardPanExtended, noModifierKeysEvt };