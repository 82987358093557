import "./register_form.scss";

import React from 'react'

import { get_api_url } from "../../js/url";


export function RegisterForm() {

  const requestRegister = () => {

    var first_name = document.getElementById("register_input_first_name").value;
    var last_name = document.getElementById("register_input_last_name").value;
    var email = document.getElementById("register_input_email").value;
    var password = document.getElementById("register_input_password").value;


    if (first_name.length === 0) {
      showError("First name not entered");
      return;
    }

    if (last_name.length === 0) {
      showError("Last name not entered");
      return;
    }

    if (email.length === 0) {
      showError("Email address not entered");
      return;
    }

    if (email.length < 3) {
      showError("Email address not valid");
      return;
    }

    if (email.includes("@") == false) {
      showError("Email address not valid");
      return;
    }

    if (password.length === 0) {
      showError("Password not entered");
      return;
    }

    if (password.length < 7) {
      showError("Password requires at least 7 characters");
      return;
    }

    var body = {
      "first_name": first_name,
      "last_name": last_name,
      "email": email,
      "password": password,
    }

    const api_url = get_api_url();
    const api_endpoint = `${api_url}/register`;

    fetch(api_endpoint, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then((response) => {
      
      if (response.status === 200) {
        response.json().then((data) => {
            hideForm();
        });
      } else if (response.status === 403) {
        response.json().then((data) => {
          console.log(data.message);
          showError(data.message);
        })
      } else {
        showError("Connection failure, please retry ...");
      }
    })
    .catch((e) => {
      console.log("cannot create account in");
      console.log(e);
      showError("Creating account failed, please retry ...")
    });
  }

  function showError(message) {
    document.getElementById("span_register_error").innerHTML = message;
    document.getElementById("div_register_error").style.display = "flex";
  }

  function hideError() {
    document.getElementById("span_register_error").innerHTML = "";
    document.getElementById("div_register_error").style.display = "none";
  }

  function hideForm() {
    hideError();
    document.getElementById("div_register_area").style.display = "none";
    document.getElementById("div_register_area").style.pointerEvents = "none";
  }

  function hideFormByClickingOutside(evt) {
    var register_area = document.getElementById("div_register_area");
    var register_form = document.getElementById("div_register_form");

    if (!register_form.contains(evt.target)) {
      hideForm();
    }
  }

  function handleKeyDown(evt) {
    if (evt.key === "Enter") {
      evt.preventDefault();
      requestRegister();
    } else if (evt.code === "Escape") {
      evt.preventDefault();
      hideForm();
    }
  }

  return (
    <div id="div_register_area" onClick={hideFormByClickingOutside} className="div_register_area hidden">
      <div id="div_register_form" className="div_register_form">
        <div className="div_register_form_item">
          <h2>Create New Account</h2>
        </div>
        <div className="div_register_form_item hidden" id="div_register_error">
          <span id="span_register_error" className="span_register_error"></span>
        </div>
        <div className="div_register_name_container">
          <div className="div_register_form_item">
            <input id="register_input_first_name" className="div_register_input_item" type="text" placeholder='First Name' onKeyDown={handleKeyDown}/>
          </div>
          <div className="div_register_form_item">
            <input id="register_input_last_name" className="div_register_input_item" type="text" placeholder='Last Name' onKeyDown={handleKeyDown}/>
          </div>
        </div>
        <div className="div_register_form_item">
          <input id="register_input_email" className="div_register_input_item" type="email" placeholder='Email Address' onKeyDown={handleKeyDown}/>
        </div>
        <div className="div_register_form_item">
          <input id="register_input_password" className="div_register_input_item" type="password" placeholder='Password' onKeyDown={handleKeyDown}/>
        </div>
        <div className="div_register_form_item align-right">
          <button id="btn_register" className="btn_register" onClick={requestRegister}><b>Create account</b></button>
        </div>
      </div>
    </div>
  );
};
    
