import { get_api_url, get_project_name } from "./url";



export async function load_project_info(on_receive_callback) {

    const api_url = get_api_url();
    const api_endpoint = `${api_url}/info`;
    const project = get_project_name();

    const response = await fetch(`${api_endpoint}?project=${project}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    }).catch((e) => {
        console.log(e)
    });

    if (response) {
        response.json().then(data => {
            console.log("project info received")
            console.log(data)
            on_receive_callback(data.data)
        })
        .catch((e) => {
            console.log(e)
        });
    }
}