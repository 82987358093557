import "./comment_form.scss";

import React from 'react'

// ---
// Callbacks
// ---

var on_comment_callbacks = [];

export function registerOnComment(func) {
  on_comment_callbacks.push(func);
};

export function onCommentEvent(comment) {
  console.log("login");
  on_comment_callbacks.forEach(function (func) {
    func(comment);
  })  
};

// ---
// Comment form
// ---

export function CommentForm() {

  const handleCommentEntered = () => {

    var comment = document.getElementById("textarea_comment").value;
    
    if (comment.length === 0) {
      showError("No comment entered");
      return;
    }

    hideForm();
    onCommentEvent(comment);
  }

  function showError(message) {
    document.getElementById("span_comment_error").innerHTML = message;
    document.getElementById("div_comment_error").style.display = "flex";
  }

  function hideError() {
    document.getElementById("span_comment_error").innerHTML = "";
    document.getElementById("div_comment_error").style.display = "none";
  }

  function hideForm() {
    hideError();
    document.getElementById("div_comment_area").style.display = "none";
  }

  function handleKeyDown(evt) {
    if (evt.ctrlKey === true && evt.key === "Enter") {
      evt.preventDefault();
      handleCommentEntered();
    } else if (evt.code === "Escape") {
      evt.preventDefault();
      hideForm();
    }
  }

  return (
      <div id="div_comment_form" className="div_comment_form" onKeyDown={handleKeyDown}>
        <div className="form_comment_item">
          <div className="div_comment_form_header">
            <h3>Add Comment</h3>
            <button id="btn_comment_send" className="btn_comment_send" onClick={() => handleCommentEntered()} 
                  title="Click here or press Ctrl + Enter to send comment">
              Send
            </button>
          </div>
        </div>
        <div className="form_comment_item hidden" id="div_comment_error">
          <span id="span_comment_error" className="span_comment_error"></span>
        </div>
        <div className="form_comment_item">
          <textarea id="textarea_comment" className="text_area_comment" type="text" placeholder=''/>
        </div>
      </div>
  );
};
    
