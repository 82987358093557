import "./login_button.scss";

import React from 'react'
import { useSignOut } from 'react-auth-kit'
import { onLogoutEvent } from "../apps/user_app";


export function LoginButton() {

    const on_click = () => {
        var login_area = document.getElementById("div_login_area");
        login_area.style.display = "flex";
        login_area.style.pointerEvents = "all";
                
        var input_email = document.getElementById("login_input_email");
        input_email.focus();
        input_email.select();
    }

    return (
        <div>
            <button id="btn_top_login" className="btn_top_login btn_top_login_color" onClick={on_click}><b>Sign in</b></button>
        </div>
    );
  }


export function LogoutButton() {

    const signOut = useSignOut();
    const handleSignOut = () => {
        signOut();
        onLogoutEvent();
    }

    return (
        <div>
            <button className="btn_top_login btn_top_login_color" onClick={handleSignOut}><b>Sign out</b></button>
        </div>
    );
  }