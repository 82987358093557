import { getAuthData } from "../src/apps/user_app";
import { onSessionExpired } from "./callbacks";
import { get_api_url, get_infer_server_url } from "./url"

class MaskPredictor {
  _input_points = [];
  _input_labels = [];
  _pending_requests = 0;
  _project_name = null;

  _current_feature = null;

  _on_error = null;
  _on_success = null;

  set_project_name(project_name) {
    this._project_name = project_name;
  }

  on_error(f) {
    this._on_error = f;
  }

  on_success(f) {
    this.on_success = f;
  }

  add_point(coordinate, label) {
    this._input_points.push(coordinate);
    this._input_labels.push(label);
  };

  reset() {
    this._input_labels = [];
    this._input_points = [];
    this._current_feature = null;
    this._pending_requests = 0;
  }

  async send_request() {

    this._pending_requests++;

    var body = {
      "input_points": this._input_points,
      "input_labels": this._input_labels,
      "project": this._project_name,
    }

    const infer_server_url = get_infer_server_url();
    const infer_server_endpoint = `${infer_server_url}/mask`;

    console.log("Send request body:");
    // TODO: ensure that no multipolygons are received
    console.log(body);

    const response = await fetch(infer_server_endpoint, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getAuthData(),
      },
      body: JSON.stringify(body)
    }).catch((e) => {
      this._pending_requests--;
      this.printError(e);
      this.on_error("Connection failure, please retry ...");
    });

    if (response) {
      console.log("Received response");
      console.log(response);
      console.log(response.blob);

      if (response.status === 200) {

        response.json()
          .then(data => {
            console.log(data)

            this._pending_requests--;
            
            if (data["status"]["code"] == 0) {
              this.on_success(data["geojson"]);
            } else if (data["status"]["code"] == 30) {
              this.on_error("This area is not supported");
              this.reset();
            } else if (data["status"]["code"] == 40) {
              this.on_error("Add more points around the object");
              // no reset required in this case
            } else {
              this.on_error(data["status"]["message"] );
              this.reset();
            }
          })
          .catch((e) => {
            this.printError(e);
            this._pending_requests--;
            this.on_error("Connection failure, please retry ...");
          });
      } else if (response.status === 401 || response.status === 422) {
        this._pending_requests--;
        onSessionExpired();
      } else {
        console.log("error while loading sam data")
        this._pending_requests--;
        this.on_error("Connection failure, please retry ...");
      }
    }
  }

  printError(e) {
    console.log(e);
    console.log("Error", e.stack);
    console.log("Error", e.name);
    console.log("Error", e.message);
    console.log("network failure, cannot communicate with backend.")
  }
};


export { MaskPredictor };
