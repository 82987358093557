
export async function retry(
    func, 
    max_retries = 10, 
    delay_in_ms = 1000, 
    cool_off = 2
  ) {
    let retryLeft = max_retries;
    let actual_delay = delay_in_ms;

    while (retryLeft > 0){
        try {
            return await func();
        }
        catch (err) { 
            await sleep(delay_in_ms)
            console.log("retrying ...")
            console.log(err)
        }
        finally {
            retryLeft -= 1;
            actual_delay *= cool_off;
        }
    }
    throw new Error(`Too many retries`);
}

export function sleep(sleep_in_ms){
    return new Promise((resolve) => {
      setTimeout(resolve, sleep_in_ms)
    });
}

