import Modify from "ol/interaction/Modify.js";
import MapBrowserEventType from 'ol/MapBrowserEventType.js';

import {getUid} from 'ol/util.js';

import {
    boundingExtent,
    buffer as bufferExtent,
    createOrUpdateFromCoordinate as createExtent,
  } from 'ol/extent.js';

  
class ModifyCustom extends Modify {

    constructor(options) {
        super(options);
    }

    /**
     * Removes a vertex from all matching features.
     * @return {boolean} True when a vertex was removed.
     * @private
     */
    removeVertex_() {
        const dragSegments = this.dragSegments_;
        const segmentsByFeature = {};
        let deleted_vertex = false;
        let deleted_ring = false;
        let component, coordinates, dragSegment, geometry, i, index, left;
        let newIndex, right, segmentData, uid;

        for (i = dragSegments.length - 1; i >= 0; --i) {
            dragSegment = dragSegments[i];
            segmentData = dragSegment[0];
            uid = getUid(segmentData.feature);

            if (segmentData.depth) {
                // separate feature components
                uid += '-' + segmentData.depth.join('-');
            }
            if (!(uid in segmentsByFeature)) {
                segmentsByFeature[uid] = {};
            }
            if (dragSegment[1] === 0) {
                segmentsByFeature[uid].right = segmentData;
                segmentsByFeature[uid].index = segmentData.index;
            } else if (dragSegment[1] == 1) {
                segmentsByFeature[uid].left = segmentData;
                segmentsByFeature[uid].index = segmentData.index + 1;
            }
        }

        for (uid in segmentsByFeature) {
            right = segmentsByFeature[uid].right;
            left = segmentsByFeature[uid].left;
            index = segmentsByFeature[uid].index;
            newIndex = index - 1;
            if (left !== undefined) {
                segmentData = left;
            } else {
                segmentData = right;
            }
            if (newIndex < 0) {
                newIndex = 0;
            }
            geometry = segmentData.geometry;
            coordinates = geometry.getCoordinates();
            component = coordinates;
            deleted_vertex = false;
            switch (geometry.getType()) {
                case 'MultiLineString':
                    if (coordinates[segmentData.depth[0]].length > 2) {
                        coordinates[segmentData.depth[0]].splice(index, 1);
                        deleted = true;
                    }
                    break;
                case 'LineString':
                    if (coordinates.length > 2) {
                        coordinates.splice(index, 1);
                        deleted_vertex = true;
                    }
                    break;
                case 'MultiPolygon':
                    component = component[segmentData.depth[1]];
                    /* falls through */
                case 'Polygon':
                    component = component[segmentData.depth[0]];
                    if (component.length > 4) {
                        if (index == component.length - 1) {
                        index = 0;
                        }
                        component.splice(index, 1);
                        deleted_vertex = true;
                        if (index === 0) {
                        // close the ring again
                        component.pop();
                        component.push(component[0]);
                        newIndex = component.length - 1;
                        }
                    } else {
                        if (segmentData.depth[0] > 0) {
                            console.log(segmentData.depth[0]);
                            deleted_ring = true;
                        }
                    }
                    break;
                default:
                // pass
            }

            if (deleted_ring) {
                coordinates.splice(segmentData.depth[0], 1)
                deleted_vertex = true;
            }

            if (deleted_vertex) {
                this.setGeometryCoordinates_(geometry, coordinates);
                const segments = [];

                if (left !== undefined) {
                    this.rBush_.remove(left);
                    segments.push(left.segment[0]);
                }

                if (right !== undefined) {
                    this.rBush_.remove(right);
                    segments.push(right.segment[1]);
                }

                if (left !== undefined && right !== undefined) {
                    /** @type {SegmentData} */
                    const newSegmentData = {
                        depth: segmentData.depth,
                        feature: segmentData.feature,
                        geometry: segmentData.geometry,
                        index: newIndex,
                        segment: segments,
                    };

                    this.rBush_.insert(
                        boundingExtent(newSegmentData.segment),
                        newSegmentData
                    );
                }
                this.updateSegmentIndices_(geometry, index, segmentData.depth, -1);

                if (this.vertexFeature_) {
                    this.overlay_.getSource().removeFeature(this.vertexFeature_);
                    this.vertexFeature_ = null;
                }

                dragSegments.length = 0;
            }
        }
        return deleted_vertex;
    }

}

export default ModifyCustom;