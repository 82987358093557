import "./login_form.scss";

import React from 'react'

import { useSignIn } from 'react-auth-kit';
import { get_api_url } from "../../js/url";
import { onLoginEvent } from "../apps/user_app";


export function LoginForm() {

  const signIn = useSignIn();  
  const requestSignIn = () => {

    var email = document.getElementById("login_input_email").value;
    var password = document.getElementById("login_input_password").value;

    if (email.length === 0) {
      showError("Email address not entered");
      return;
    }

    if (password.length === 0) {
      showError("Password not entered");
      return;
    }

    var body = {
      "email": email,
      "password": password,
    }

    const api_url = get_api_url();
    const api_endpoint = `${api_url}/auth`;

    fetch(api_endpoint, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then((response) => {
      
      if (response.status === 200) {
        response.json().then((data) => {
    
          let auth_state = {
            user: data.user,
            roles: data.roles,
          }

          let res = signIn({
            token: data.token,
            tokenType: 'Bearer',
            authState: auth_state,
            expiresIn: 60 * 12,
          })
             
          if (res) {
            hideForm();
            var auth_data = `Bearer ${data.token}`;
            onLoginEvent(data.user, auth_data, data.roles);
          } else {
            showError("Login failed")
          }

        });
      } else if (response.status === 401) {
        response.json().then((data) => {
          console.log(data.message);
          showError(data.message);
        })
      } else {
        console.log("Cannot login")
        console.log(data)
        showError("Connection failure, please retry ...");
      }
    })
    .catch((e) => {
      console.log("cannot sign in");
      console.log(e);
      showError("Login failed")
    });
  }

  function showError(message) {
    document.getElementById("span_login_error").innerHTML = message;
    document.getElementById("div_login_error").style.display = "flex";
  }

  function hideError() {
    document.getElementById("span_login_error").innerHTML = "";
    document.getElementById("div_login_error").style.display = "none";
  }

  function hideForm() {
    hideError();
    document.getElementById("div_login_area").style.display = "none";
    document.getElementById("div_login_area").style.pointerEvents = "none";
  }

  function hideFormByClickOutside(evt) {
    var login_area = document.getElementById("div_login_area");
    var login_form = document.getElementById("div_login_form");

    if (!login_form.contains(evt.target)) {
      hideForm();
    }
  }

  function handleKeyDown(evt) {
    if (evt.key === "Enter") {
      evt.preventDefault();
      requestSignIn();
    } else if (evt.code === "Escape") {
      evt.preventDefault();
      hideForm();
    }
  }

  function showCreateAccount() {
    hideError();

    var register_area = document.getElementById("div_register_area");
    register_area.style.display = "flex";
    register_area.style.pointerEvents = "all";
            
    var input_email = document.getElementById("register_input_first_name");
    input_email.focus();
    input_email.select();
  }


  return (
    <div id="div_login_area" onClick={hideFormByClickOutside} className="div_login_area hidden">
      <div id="div_login_form" className="div_login_form">
        <div className="div_login_form_item">
          <h2>Welcome</h2>
        </div>
        <div className="div_login_form_item hidden" id="div_login_error">
          <span id="span_login_error" className="span_login_error"></span>
        </div>
        <div className="div_login_form_item">
          <input id="login_input_email" className="div_login_input_item" type="email" placeholder='Email Address' onKeyDown={handleKeyDown}/>
        </div>
        <div className="div_login_form_item">
          <input id="login_input_password" className="div_login_input_item" type="password" placeholder='Password' onKeyDown={handleKeyDown}/>
        </div>
        <div className="div_login_form_item">
          {/*<button id="btn_show_create_account" className="btn_show_create_account" onClick={() => showCreateAccount()}><b>Create account</b></button>*/}
          <button id="btn_login" className="btn_login" onClick={() => requestSignIn()}><b>Sign in</b></button>
        </div>
      </div>
    </div>
  );
};
    
