
// ---
// Function to return user is an admin
// ---

import { fromLonLat, toLonLat } from "ol/proj";
import { CONFIG_DEV_MODE, CONFIG_PROJECT, CONFIG_WFS_SERVER_URL, CONFIG_WFS_SERVER_URL_DEV } from "./config";
import { CONFIG_API_URL, CONFIG_API_URL_DEV } from "./config";
import { CONFIG_TILE_SERVER_URL, CONFIG_TILE_SERVER_URL_DEV } from "./config";
import { CONFIG_INFER_SERVER_URL, CONFIG_INFER_SERVER_URL_DEV } from "./config";
import { CONFIG_GEOMETRY_SERVER_URL, CONFIG_GEOMETRY_SERVER_URL_DEV } from "./config";
import { getAuthData, getUserData, isUserLoggedIn, setUserData } from "../src/apps/user_app";
import { showInfoText } from "./info";
import { onSessionExpired } from "./callbacks";

// ---
// Boolean to indicate if admin only
// ---

export function is_admin() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    if (urlParams.has('admin') === true) {
      return true;
    } else {
      return false;
    }
  }

// ---
// Boolean to indicate if view mode only
// ---

export function is_viewer() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  if (urlParams.has('viewer') === true) {
    return true;
  } else {
    return false;
  }
}


// ---
// Boolean to indicate if buildings from Austrian cadastre should be shown
// ---

export function is_buildings() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  if (urlParams.has('buildings') === true) {
    return true;
  } else {
    return false;
  }
}

// ---
// Get project
// ---
  
export function get_project_name() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    if (urlParams.has('project') === true) {
      return urlParams.get('project');
    } else {
      return CONFIG_PROJECT;
    }
  }
  

// ---
// Get view
// ---
  
export function get_view_from_url() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  if (urlParams.has('lon') === true && 
      urlParams.has('lat') === true && 
      urlParams.has('zoom') === true) {    

      const lon = parseFloat(urlParams.get("lon"));
      const lat = parseFloat(urlParams.get("lat"));
      const zoom = parseFloat(urlParams.get("zoom"));

      if (!isNaN(lon) && !isNaN(lat) && !isNaN(zoom)) {
        return {
          "center": fromLonLat([lon, lat]),
          "zoom": zoom,
        };
      } 
  }

  return null;
}

// ---
// Set view
// ---

export function set_view_to_url(center, zoom) {

  const lonlat = toLonLat(center);
  const url = new URL(window.location); 
  
  url.searchParams.set('lon', lonlat[0]); 
  url.searchParams.set('lat', lonlat[1]); 
  url.searchParams.set('zoom', zoom); 
  
  window.history.pushState({}, '', url);
}

// --- 
// Get selected polygon
// --- 

export function get_selected_polygon_from_url() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  if (urlParams.has('selected') === true) { 
      const feature_id = urlParams.get("selected");
      return feature_id;
  }

  return null;
}

// ---
// Set selected polygon
// ---

export function set_selected_polygon_to_url(feature_id) {

  const url = new URL(window.location); 

  if (feature_id != null) {
    url.searchParams.set('selected', feature_id); 
  } else {
    url.searchParams.delete('selected');  
  }

  window.history.pushState({}, '', url);
}

// ---
// Get API URL
// ---

export function get_api_url() {
  if (CONFIG_DEV_MODE == true) {
    return CONFIG_API_URL_DEV;
  } else {
    return CONFIG_API_URL;
  }
}

// ---
// Get Tile Server URL
// ---

export function get_tile_server_url() {
  if (CONFIG_DEV_MODE == true) {
    return CONFIG_TILE_SERVER_URL_DEV;
  } else {
    return CONFIG_TILE_SERVER_URL;
  }
}

// ---
// Get WFS Server URL
// ---

export function get_wfs_server_url() {
  if (CONFIG_DEV_MODE == true) {
    return CONFIG_WFS_SERVER_URL_DEV;
  } else {
    return CONFIG_WFS_SERVER_URL;
  }
}

// ---
// Get Geometry Server URL
// ---

export function get_geometry_server_url() {
  if (CONFIG_DEV_MODE == true) {
    return CONFIG_GEOMETRY_SERVER_URL_DEV;
  } else {
    return CONFIG_GEOMETRY_SERVER_URL;
  }
}

// ---
// Get Infer Server URL
// ---

export function get_infer_server_url() {
  if (CONFIG_DEV_MODE == true) {
    return CONFIG_INFER_SERVER_URL_DEV;
  } else {
    return CONFIG_INFER_SERVER_URL;
  }
}

// --- 
// Get task id
// ---

export function get_task_id() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  if (urlParams.has('task') === true) { 
      const task_id = urlParams.get("task");
      return task_id;
  }

  return null;
}

export function is_task_id_set() {
  return get_task_id() != null;
}

// ---
// Store current state/URL for user
// ---

export function save_current_state() {

  if (isUserLoggedIn() === false) {
    return;
  }

  if (window.location.search.length == 0) {
    return;
  }

  save_user_state(window.location.href);
}

export function reset_user_state() {
  save_user_state("");
}

export function save_user_state(user_state) {

  let user_data = getUserData();
  user_data.last_state = user_state;
  setUserData(user_data);

  const api_url = get_api_url();
  const api_endpoint = `${api_url}/user`;    
  
  const body = {
    "email": user_data.email,
    "data": {
      "last_state": user_data.last_state,
    }
  }

  fetch(api_endpoint, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getAuthData(),
    },
    body: JSON.stringify(body)
  })
  .then((response) => {
    
    if (response.status === 200) {
    } else if (response.status === 401 || response.status === 422) {
      onSessionExpired();
    }
     else {
      showInfoText("Connection failure, please retry ...");
    }
  })
  .catch((e) => {
    console.log("cannot send user state");
    console.log(e);
    showInfoText("Connection failure, please retry ...");
  });
}
